<template>
  <div>
    <v-text-field
      v-if="mask"
      :success-messages="showTinVerification && user.tinVerifiedDate ? returnMessage : null"
      dense
      :value="displayValue"
      @input="onInput"
      :label="labelValue"
      :country="country"
      v-bind="$attrs"
      v-on="$listeners"
      v-mask="computedMask"
      @blur="onBlur"
      @focus="onFocus"
      :rules="rulesArray"
      :loading="loading"
    >
      <template v-slot:append-outer v-if="tooltip">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="primary" dark>
              mdi-information-outline
            </v-icon>
          </template>
          <span>{{ tooltip }}</span>
        </v-tooltip>
      </template>
    </v-text-field>
    <AlertDisplay ref="alertRef" durationOption="never" />
  </div>
</template>

<script>
import AlertDisplay from "@/gapp-components/components/display/AlertDisplay.vue";
import UtilService from "@/gapp-components/services/util.service";
import { mapGetters } from "vuex";

export default {
  name: "TinField",
  components: { AlertDisplay },
  data: () => ({
    loading: false,

    show: false,
    labelValue: null,
    displayValue: "",
    tinValue: "",
    maskObj: null,
    clearOnFocus: false,
    rulesArray: [],

    hideMask: "",
    mask: ["###-##-####"],
    tokens: {
      "#": { pattern: /\d/ },
      X: { pattern: /[0-9a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
      S: { pattern: /[a-zA-Z]/ },
      A: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
      a: { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
      "*": { pattern: /./ },
      "!": { escape: true }
    }
  }),
  props: {
    value: String,
    country: { type: Object, default: () => ({}) },
    rules: Array,
    user: Object,
    showTinVerification: { type: Boolean, required: false, default: false },
    tooltip: { type: String, default: null },
    saveOnBlur: { type: Boolean, default: false }
  },
  methods: {
    onInput(obj) {
      if (this.clearOnFocus) {
        obj = "";
        this.clearOnFocus = false;
      }
      this.displayValue = obj;
      this.tinValue = obj;
      this.$emit("input", obj);
    },
    onChange(obj) {
      this.displayValue = obj;
      this.tinValue = obj;
      this.$emit("input", obj);
    },
    maskTin(tin) {
      if (!tin) return "";
      let parts = tin.split("-");
      let lastPart = parts.pop();
      let maskedParts = parts.map(part => part.replace(/\d/g, "*"));

      return [...maskedParts, lastPart].join("-");
    },
    onFocus() {
      this.show = true;
      this.clearOnFocus = true;
      this.tinValue = "";
      this.displayValue = "";
      this.$emit("input", "");
    },
    onBlur() {
      this.show = false;
      this.displayValue = this.maskTin(this.tinValue);
      if (this.saveOnBlur && this.displayValue !== "") {
        this.onSave();
      }
    },
    onSave() {
      if (this.validate() !== true) {
        return;
      }

      if (!this.user) {
        return;
      }

      let service;
      if (this.user.id !== this.currentUser.user.id && this.$privilege.hasPrivilege("USER_UPDATE")) {
        service = this.$api.post(`/api/users/${this.user.id}/setTin`, { tin: this.tinValue });
      } else if (this.user.id === this.selectedParticipant.user.id) {
        service = this.$api.post("/api/users/setTin", { tin: this.tinValue });
      } else {
        return;
      }

      this.loading = true;

      service
        .then(() => {
          this.$emit("input", this.tinValue);
        })
        .catch(this.handleError)
        .finally(() => {
          this.loading = false;
        });
    },
    handleError(error) {
      if (error.response?.data?.errors) {
        const tinError = error.response.data.errors.find(err => err.message === "TIN already exists");
        if (tinError) {
          this.$refs.alertRef.error(
            `${this.labelValue} already exists. Please contact Program Headquarters at ${this.selectedProgram.phoneType1Description} for assistance.`
          );
        } else {
          console.error("Error updating", this.labelValue, " :", error.response.data.errorMessage || "Unknown error");
        }
      } else {
        console.error("An unexpected error occurred.");
      }
    },
    resetAlertDisplay() {
      if (this.$refs.alertRef) {
        this.$refs.alertRef.resetAlert();
      }
    },
    resetRulesArray() {
      this.rulesArray = [];
      if (this.rules) {
        this.rulesArray = this.rulesArray.concat(this.rules);
      }
      this.rulesArray = this.rulesArray.concat(this.validate);
    },
    /**
     * Update the "hide" version of the mask to allow asterisks
     */
    updateHideMask() {
      let hiddenMask = "";
      let m = this.mask[0];
      for (let i = 0; i < m.length; i++) {
        let c = m.charAt(i);
        if (c != "-") {
          c = "*";
        }
        hiddenMask += c;
      }
      this.hideMask = [hiddenMask];
    },
    /**
     * Validate the TIN based on regex stored on the country
     */
    validate() {
      let obj = this.tinValue;

      // This method will only provide validation, not requirement validation
      if (!obj) {
        return true;
      }

      // Don't validate if the user already has a saved TIN
      if (obj && this.user && this.user.tin == obj) {
        return true;
      }

      let label = this.labelValue || "TIN (Select Country First)";

      if (this.country && this.country.participantTinRegex && obj) {
        const regex = new RegExp(this.country.participantTinRegex);
        if (!regex.test(obj)) {
          return `Invalid ${label}`;
        }
      }

      if (
        this.user &&
        this.user.id !== this.selectedParticipant.user.id &&
        !this.$privilege.hasPrivilege("USER_UPDATE")
      ) {
        return `Current user does not have access to update ${label}`;
      }

      return true;
    },
    userChange() {
      if (this.user && this.user.tin) {
        let lastDigits = "";

        if (this.country.name === "CAN") {
          lastDigits = this.user.tin.slice(-3);
          this.displayValue = "***-***-" + lastDigits;
        } else if (this.country.name === "USA") {
          lastDigits = this.user.tin.slice(-4);
          this.displayValue = "***-**-" + lastDigits;
        } else {
          this.displayValue = this.user.tin;
        }
      } else {
        this.tinValue = "";
        this.displayValue = "";
      }
    }
  },
  watch: {
    country: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.mask = value.participantTinMask || ["###-##-####"];
          this.updateHideMask();
          this.labelValue = value.participantTinName || "TIN (Select Country First)";
        } else {
          this.mask = ["###-##-####"];
          this.updateHideMask();
          this.labelValue = "TIN (Select Country First)";
        }
      }
    },
    user: "userChange",
    rules: "resetRulesArray"
  },
  created() {
    this.labelValue = this.country?.participantTinName || "TIN (Select Country First)";
    this.resetRulesArray();
    this.updateHideMask();
    this.userChange();
  },
  computed: {
    ...mapGetters(["currentUser", "selectedParticipant", "selectedClient", "selectedProgram"]),
    computedMask() {
      if (this.show) {
        return { mask: [...this.mask], tokens: this.tokens };
      } else {
        return { mask: [...this.hideMask], tokens: this.tokens };
      }
    },
    returnMessage() {
      if (this.user) {
        return (
          this.country.participantTinName +
          " verified on " +
          UtilService.formatDateClient(this.user.tinVerifiedDate, "MM/DD/YYYY", this.selectedClient)
        );
      } else {
        return "";
      }
    }
  }
};
</script>
